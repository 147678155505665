import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Store from "@/store/index";
//-----------------管理用
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import Error from "../views/Error.vue";
//-----------------売上管理
import profit_jyutyuinput from "../views/profit_jyutyuinput.vue";
import Ankenlist from "../views/Ankenlist.vue";
import Uriagelist from "../views/Uriagelist.vue";
import Shiirelist from "../views/Shiirelist.vue";
import Misyoninlist from "../views/Misyoninlist.vue";
import Nyukinyoteilist from "../views/Nyukinyoteilist.vue";
import Seikyulist from "../views/Seikyulist.vue";
import Seikyunolist from "../views/Seikyunolist.vue";
import Datalock from "../views/Datalock.vue";
import Datalocklist from "../views/Datalocklist.vue";
import Datalockedit from "../views/Datalockedit.vue";
//-----------------帳票作成
import Seikyusyocreate from "../views/Seikyusyocreate.vue";
import Seikyusyocreatenotsales from "../views/Seikyusyocreatenotsales.vue";
import Getujikanricreate from "../views/Getujikanricreate.vue";
import Torihikiuriagecreate from "../views/Torihikiuriagecreate.vue";
import Koukokuuriagecreate from "../views/Koukokuuriagecreate.vue";
import Getujibunkatsucreate from "../views/Getujibunkatsucreate.vue";
import Uriageseikyucreate from "../views/Uriageseikyucreate.vue";
import Hakkoseikyucreate from "../views/Hakkoseikyucreate.vue";
import Nyukinyoteicreate from "../views/Nyukinyoteicreate.vue";
import Paymentyoteicreate from "../views/Paymentyoteicreate.vue";
import Shiireshiharaicreate from "../views/Shiireshiharaicreate.vue";
//-----------------マスタ情報管理
import Syouhinlist from "../views/Syouhinlist.vue";
import Uriagesakilist from "../views/Uriagesakilist.vue";
import Shiiresakilist from "../views/Shiiresakilist.vue";
import Syainlist from "../views/Syainlist.vue";
import Kouzalist from "../views/Kouzalist.vue";
import Messagelist from "../views/Messagelist.vue";
import Salestargetlist from "../views/Salestargetlist.vue";
import Holidaylist from "../views/Holidaylist.vue";
import Sousarirekilist from "../views/Sousarirekilist.vue";
//-----------------マスタ情報編集
import Syouhinedit from "../views/Syouhinedit.vue";
import Uriagesakiedit from "../views/Uriagesakiedit.vue";
import Shiiresakiedit from "../views/Shiiresakiedit.vue";
import Kouzaedit from "../views/Kouzaedit.vue";
import Messageedit from "../views/Messageedit.vue";
import Salestargetedit from "../views/Salestargetedit.vue";
import Holidayedit from "../views/Holidayedit.vue";
import Syainedit from "../views/Syainedit.vue";
//-----------------申請書管理
import Shinseisyolist from "../views/Shinseisyolist.vue";
import shinsei_Karibaraiedit from "../views/shinsei_Karibaraiedit.vue";
import shinsei_Shutchoedit from "../views/shinsei_Shutchoedit.vue";
import shinsei_Settaiedit from "../views/shinsei_Settaiedit.vue";
import shinsei_Kyushokunegaiedit from "../views/shinsei_Kyushokunegaiedit.vue";
import shinsei_Shayoshashiyoedit from "../views/shinsei_Shayoshashiyoedit.vue";
import shinsei_Keihiseisanedit from "../views/shinsei_Keihiseisanedit.vue";
import shinsei_Kyuyoshoyokozatorokuedit from "../views/shinsei_Kyuyoshoyokozatorokuedit.vue";
import shinsei_Jushohenkotsukinteateedit from "../views/shinsei_Jushohenkotsukinteateedit.vue";
import shinsei_Keihikozatorokuedit from "../views/shinsei_Keihikozatorokuedit.vue";
import shinsei_Ringishoedit from "../views/shinsei_Ringishoedit.vue";
import shinsei_Zangyoedit from "../views/shinsei_Zangyoedit.vue";
import shinsei_Kyukatodokeedit from "../views/shinsei_Kyukatodokeedit.vue";
import shinsei_Remoteworkedit from "../views/shinsei_Remoteworkedit.vue";
//-----------------情報分析
import Dataportal from "../views/Dataportal.vue";
import Analyprofit from "../views/Analyprofit.vue";
import Analyprofit2 from "../views/Analyprofit2.vue";
import AnalyprofitBase from "../views/AnalyprofitBase.vue";
import Datadiagnosis from "../views/Datadiagnosis.vue";
//-----------------勤怠管理
import Attendancelist from "../views/Attendancelist.vue";
import Attendanceedit from "../views/Attendanceedit.vue";
//-----------------文書管理
import Doclist from "../views/Doclist.vue";
import DocEdit from "../views/DocEdit.vue";
//-----------------その他
import Blank from "../views/Blank.vue";
import Empty from "../views/Empty.vue";
import Test from "../views/Test.vue";
import Salert from "../views/Salert.vue";
import Toaster from "../views/Toaster.vue";
import Loader from "../views/Loader.vue";
import Alerts from "../views/Alerts.vue";
import Vueselect from "../views/Vueselect.vue";
import Teble4goodtable from "../views/Teble4goodtable.vue";
import Modal from "../views/Modal.vue";
import Analytics_dashboard from "../views/Analytics_dashboard.vue";
//-----------------新機能用モック
import profit_Shiiresakiedit from "../views/profit_Shiiresakiedit.vue";
import profit_Uriagesakiedit from "../views/profit_Uriagesakiedit.vue";
import profit_itemlist from "../views/profit_itemlist.vue";
import Itemedit4billbord from "../views/Itemedit4billbord.vue";
import Itemedit4posterjack from "../views/Itemedit4posterjack.vue";
import Itemedit4commercialfacility from "../views/Itemedit4commercialfacility.vue";
import Itemedit4digitalbillbord from "../views/Itemedit4digitalbillbord.vue";
import Itemedit4koutu from "../views/Itemedit4koutu.vue";
import Itemedit4exmedia from "../views/Itemedit4exmedia.vue";
import Itemedit4poster from "../views/Itemedit4poster.vue";
import Itemedit4sonota from "../views/Itemedit4sonota.vue";
import { Cl_common } from "@/functions/Cl_common";

const routes: Array<RouteRecordRaw> = [
  //-----------------管理用

  {
    path: "/",
    name: "Login",
    component: Login,
    meta: { title: "MEDIA DEPT | ログイン", desc: "ログイン", isPublic: true },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/:catchAll(.*)",
    name: "Error",
    component: Error,
  },
  //-----------------売上管理
  {
    path: "/jyutyuinput",
    name: "jyutyuinput",
    component: profit_jyutyuinput,
  },
  {
    path: "/ankenlist",
    name: "Ankenlist",
    component: Ankenlist,
  },
  {
    path: "/uriagelist",
    name: "Uriagelist",
    component: Uriagelist,
  },
  {
    path: "/shiirelist",
    name: "Shiirelist",
    component: Shiirelist,
  },
  {
    path: "/misyoninlist",
    name: "Misyoninlist",
    component: Misyoninlist,
  },
  {
    path: "/nyukinyoteilist",
    name: "Nyukinyoteilist",
    component: Nyukinyoteilist,
  },
  {
    path: "/seikyulist",
    name: "Seikyulist",
    component: Seikyulist,
  },
  {
    path: "/seikyunolist",
    name: "Seikyunolist",
    component: Seikyunolist,
  },
  {
    path: "/datalock",
    name: "Datalock",
    component: Datalock,
  },
  {
    path: "/datalocklist",
    name: "Datalocklist",
    component: Datalocklist,
  },
  {
    path: "/datalockedit",
    name: "Datalockedit",
    component: Datalockedit,
  },
  //-----------------帳票作成
  {
    path: "/seikyusyocreate",
    name: "Seikyusyocreate",
    component: Seikyusyocreate,
  },
  {
    path: "/seikyusyocreatenotsales",
    name: "Seikyusyocreatenotsales",
    component: Seikyusyocreatenotsales,
  },
  {
    path: "/getujikanricreate",
    name: "Getujikanricreate",
    component: Getujikanricreate,
  },
  {
    path: "/torihikiuriagecreate",
    name: "Torihikiuriagecreate",
    component: Torihikiuriagecreate,
  },
  {
    path: "/koukokuuriagecreate",
    name: "Koukokuuriagecreate",
    component: Koukokuuriagecreate,
  },
  {
    path: "/getujibunkatsucreate",
    name: "Getujibunkatsucreate",
    component: Getujibunkatsucreate,
  },
  {
    path: "/uriageseikyucreate",
    name: "Uriageseikyucreate",
    component: Uriageseikyucreate,
  },
  {
    path: "/hakkoseikyucreate",
    name: "Hakkoseikyucreate",
    component: Hakkoseikyucreate,
  },
  {
    path: "/nyukinyoteicreate",
    name: "Nyukinyoteicreate",
    component: Nyukinyoteicreate,
  },
  {
    path: "/paymentyoteicreate",
    name: "Paymentyoteicreate",
    component: Paymentyoteicreate,
  },
  {
    path: "/shiireshiharaicreate",
    name: "Shiireshiharaicreate",
    component: Shiireshiharaicreate,
  },
  //-----------------マスタ情報管理
  {
    path: "/syouhinlist",
    name: "Syouhinlist",
    component: Syouhinlist,
  },
  {
    path: "/uriagesakilist",
    name: "Uriagesakilist",
    component: Uriagesakilist,
  },
  {
    path: "/shiiresakilist",
    name: "Shiiresakilist",
    component: Shiiresakilist,
  },
  {
    path: "/syainlist",
    name: "Syainlist",
    component: Syainlist,
  },
  {
    path: "/kouzalist",
    name: "Kouzalist",
    component: Kouzalist,
  },
  {
    path: "/messagelist",
    name: "Messagelist",
    component: Messagelist,
  },
  {
    path: "/salestargetlist",
    name: "Salestargetlist",
    component: Salestargetlist,
  },
  {
    path: "/holidaylist",
    name: "Holidaylist",
    component: Holidaylist,
  },
  {
    path: "/sousarirekilist",
    name: "Sousarirekilist",
    component: Sousarirekilist,
  },
  //-----------------マスタ情報編集
  {
    path: "/syouhinedit",
    name: "Syouhinedit",
    component: Syouhinedit,
  },
  {
    path: "/uriagesakiedit",
    name: "Uriagesakiedit",
    component: Uriagesakiedit,
  },
  {
    path: "/shiiresakiedit",
    name: "Shiiresakiedit",
    component: Shiiresakiedit,
  },
  {
    path: "/kouzaedit",
    name: "Kouzaedit",
    component: Kouzaedit,
  },
  {
    path: "/messageedit",
    name: "Messageedit",
    component: Messageedit,
  },
  {
    path: "/salestargetedit",
    name: "Salestargetedit",
    component: Salestargetedit,
  },
  {
    path: "/holidayedit",
    name: "Holidayedit",
    component: Holidayedit,
  },
  {
    path: "/syainedit",
    name: "Syainedit",
    component: Syainedit,
  },
  //-----------------申請書管理
  {
    path: "/shinseisyolist",
    name: "Shinseisyolist",
    component: Shinseisyolist,
  },
  {
    path: "/shinsei_karibaraiedit",
    name: "shinsei_Karibaraiedit",
    component: shinsei_Karibaraiedit,
  },
  {
    path: "/shinsei_Shutchoedit",
    name: "shinsei_Shutchoedit",
    component: shinsei_Shutchoedit,
  },
  {
    path: "/shinsei_Settaiedit",
    name: "shinsei_Settaiedit",
    component: shinsei_Settaiedit,
  },
  {
    path: "/shinsei_Kyushokunegaiedit",
    name: "shinsei_Kyushokunegaiedit",
    component: shinsei_Kyushokunegaiedit,
  },
  {
    path: "/shinsei_Shayoshashiyoedit",
    name: "shinsei_Shayoshashiyoedit",
    component: shinsei_Shayoshashiyoedit,
  },
  {
    path: "/shinsei_Keihiseisanedit",
    name: "shinsei_Keihiseisanedit",
    component: shinsei_Keihiseisanedit,
  },
  {
    path: "/shinsei_Kyuyoshoyokozatorokuedit",
    name: "shinsei_Kyuyoshoyokozatorokuedit",
    component: shinsei_Kyuyoshoyokozatorokuedit,
  },
  {
    path: "/shinsei_Jushohenkotsukinteateedit",
    name: "shinsei_Jushohenkotsukinteateedit",
    component: shinsei_Jushohenkotsukinteateedit,
  },
  {
    path: "/shinsei_Keihikozatorokuedit",
    name: "shinsei_Keihikozatorokuedit",
    component: shinsei_Keihikozatorokuedit,
  },
  {
    path: "/shinsei_Ringishoedit",
    name: "shinsei_Ringishoedit",
    component: shinsei_Ringishoedit,
  },
  {
    path: "/shinsei_Zangyoedit",
    name: "shinsei_Zangyoedit",
    component: shinsei_Zangyoedit,
  },
  {
    path: "/shinsei_Kyukatodokeedit",
    name: "shinsei_Kyukatodokeedit",
    component: shinsei_Kyukatodokeedit,
  },
  {
    path: "/shinsei_Remoteworkedit",
    name: "shinsei_Remoteworkedit",
    component: shinsei_Remoteworkedit,
  },
  //-----------------情報分析
  {
    path: "/dataportal",
    name: "Dataportal",
    component: Dataportal,
  },
  {
    path: "/analyprofit",
    name: "Analyprofit",
    component: Analyprofit,
  },
  {
    path: "/analyprofit2",
    name: "Analyprofit2",
    component: Analyprofit2,
  },
  {
    path: "/analyprofitBase",
    name: "AnalyprofitBase",
    component: AnalyprofitBase,
  },
  {
    path: "/datadiagnosis",
    name: "Datadiagnosis",
    component: Datadiagnosis,
  },
  //-----------------勤怠管理
  {
    path: "/attendancelist",
    name: "Attendancelist",
    component: Attendancelist,
  },
  {
    path: "/attendanceedit",
    name: "Attendanceedit",
    component: Attendanceedit,
  },
  //-----------------文書管理
  {
    path: "/doclist",
    name: "Doclist",
    component: Doclist,
  },
  {
    path: "/docedit",
    name: "DocEdit",
    component: DocEdit,
  },
  //-----------------その他
  {
    path: "/test",
    name: "Test",
    component: Test,
  },
  {
    path: "/blank",
    name: "Blank",
    component: Blank,
  },
  {
    path: "/empty",
    name: "Empty",
    component: Empty,
  },
  {
    path: "/toaster",
    name: "Toaster",
    component: Toaster,
  },
  {
    path: "/salert",
    name: "Salert",
    component: Salert,
  },
  {
    path: "/loader",
    name: "Loader",
    component: Loader,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: Alerts,
  },
  {
    path: "/teble4goodtable",
    name: "Teble4goodtable",
    component: Teble4goodtable,
  },
  {
    path: "/vueselect",
    name: "Vueselect",
    component: Vueselect,
  },
  {
    path: "/modal",
    name: "Modal",
    component: Modal,
  },
  {
    path: "/analytics_dashboard",
    name: "Analytics_dashboard",
    component: Analytics_dashboard,
  },
  //-----------------新機能用モック
  {
    path: "/profit_shiiresakiedit",
    name: "profit_Shiiresakiedit",
    component: profit_Shiiresakiedit,
  },
  {
    path: "/profit_uriagesakiedit",
    name: "profit_Uriagesakiedit",
    component: profit_Uriagesakiedit,
  },
  {
    path: "/profit_itemlist",
    name: "profit_itemlist",
    component: profit_itemlist,
  },
  {
    path: "/itemedit4billbord",
    name: "Itemedit4billbord",
    component: Itemedit4billbord,
  },
  {
    path: "/itemedit4posterjack",
    name: "Itemedit4posterjack",
    component: Itemedit4posterjack,
  },
  {
    path: "/itemedit4commercialfacility",
    name: "Itemedit4commercialfacility",
    component: Itemedit4commercialfacility,
  },
  {
    path: "/itemedit4digitalbillbord",
    name: "Itemedit4digitalbillbord",
    component: Itemedit4digitalbillbord,
  },
  {
    path: "/itemedit4koutu",
    name: "Itemedit4koutu",
    component: Itemedit4koutu,
  },
  {
    path: "/itemedit4exmedia",
    name: "Itemedit4exmedia",
    component: Itemedit4exmedia,
  },
  {
    path: "/itemedit4poster",
    name: "Itemedit4poster",
    component: Itemedit4poster,
  },
  {
    path: "/itemedit4sonota",
    name: "Itemedit4sonota",
    component: Itemedit4sonota,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  Store.commit("revStore");
  if (to.matched.some((page) => page.meta.isPublic) || Store.state.token_id) {
    //activeクラスの付け替え
    //-------------
    if (document != null) {
      try {
        const parent = document.getElementById("js-nav-menu");
        if (parent != null) {
          const elements = parent.getElementsByTagName("li");
          Array.prototype.forEach.call(elements, function (element) {
            if (!element.classList.contains("open")) {
              element.classList.remove("active");
            }
          });

          // console.log(parent.querySelector("a[href='" + to.path + "']"));
          //2.自身のactivを付ける
          if (
            to.path != "/dashboard" &&
            parent.querySelector("a[href='" + to.path + "']") != null
          ) {
            parent
              .querySelector("a[href='" + to.path + "']")!
              .closest("li")!
              .classList.add("active");
          }
        }
      } catch (error) {
        Cl_common.logger(error);
        //画面遷移に問題が出るので、エラーは無視する
      }
    }

    //-------------
    next();
  } else {
    next("/");
  }
});

export default router;
