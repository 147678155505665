
import { Options, Vue } from "vue-class-component";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { VueGoodTable } from "vue-good-table-next";
import { Cl_common } from "@/functions/Cl_common";
import Mo_modal_l from "@/components/molecules/Mo_modal_n.vue";
import { DtoItemsGetReqImple } from "mediadept-common/src/common/dto/DtoItemsGetReqImple";
import { DtoItemsGetResImple } from "mediadept-common/src/common/dto/DtoItemsGetResImple";
import { DtoItemsPostReq } from "mediadept-common/src/common/dto/abst/DtoItemsPostReq";
import { DtoItemsPostRes } from "mediadept-common/src/common/dto//abst/DtoItemsPostRes";
import { Cl_item } from "@/functions/Cl_item";
import { CONST_LIST_DEFO_COUNT } from "@/constants/constant";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { Cl_staff } from "@/functions/Cl_staff";
import { DtoStaffsGetResImple } from "mediadept-common/src/common/dto/DtoStaffsGetResImple";
import {
  CONST_OP_ITEMTYPE,
  CONST_OP_OWNER,
  CONST_OP_TERM_ITEM,
} from "mediadept-common/src/common/constant/Constants";
import { Cl_filemaster } from "@/functions/Cl_filemaster";
import { DtoFileMastersGetReqImple } from "mediadept-common/src/common/dto/DtoFileMastersGetReqImple";
import { DtoFileReportsGetReqImple } from "mediadept-common/src/common/dto/DtoFileReportsGetReqImple";
import { Cl_filereport } from "@/functions/Cl_filereport";
import { DtoOutputqueuesGetReqImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetReqImple";
import { DtoOutputqueuesGetResImple } from "mediadept-common/src/common/dto/DtoOutputqueuesGetResImple";
import { Cl_outputqueue } from "@/functions/Cl_outputqueue";

@Options({
  components: {
    vSelect,
    VueGoodTable,
    Mo_modal_l,
    Mo_modal_alert,
  },
})
export default class Syouhinlist extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  delegate = new DtoItemsGetResImple();
  domItemManagNumber = "";
  domItemName = "";
  domItemType = [];
  domOwner = [];
  domArea = "";
  domTerm = "";
  //ボタン制御用
  btnDisable = false;

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------

  opItemtype = CONST_OP_ITEMTYPE;
  opOwner = CONST_OP_OWNER;
  opTerm = CONST_OP_TERM_ITEM;
  // opTerm = [
  //   { label: "長期", code: 1 },
  //   { label: "短期", code: 2 },
  // ];

  strTypes = [
    "",
    "ビルボード",
    "ポスタージャック",
    "商業施設",
    "デジタルビルボード",
    "交通",
    "その他",
  ];
  strOwner = ["", "自社", "他社", "交通", "リムジン", "その他"];
  strArea = [
    "",
    "シティ",
    "ストリート",
    "ロード",
    "高速",
    "駅",
    "社内",
    "車体",
  ];

  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "商品管理番号",
      field: "c_item_manag_name",
    },
    {
      label: "商品名",
      field: "c_item_name",
    },
    {
      label: "商品種別",
      field: "c_item_type",
    },
    {
      label: "所有",
      field: "c_item_owner",
    },
    {
      label: "エリア・路線",
      field: "c_item_area",
    },
  ];

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalErrorConf = "";

  //----------------------
  //制御用メソッド
  //----------------------
  async mounted() {
    await this.getlist(1);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  /**
   * 一覧データを読み込む
   */
  async getlist(currentPage: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoItemsGetReqImple();

      //検索条件設定
      dto.pager.count = CONST_LIST_DEFO_COUNT;
      dto.pager.cursor = currentPage;

      dto.itemManagName = this.domItemManagNumber;
      dto.itemName = this.domItemName;
      if (0 < this.domItemType.length) {
        dto.itemType = this.domItemType.join(",");
      }
      if (0 < this.domOwner.length) {
        dto.itemOwner = this.domOwner.join(",");
      }
      dto.itemArea = this.domArea;
      if (null != this.domTerm && "" != this.domTerm) {
        dto.itemTerm = this.domTerm;
      }

      //API実行
      //-------
      this.delegate = await Cl_item.getList(dto);
      Cl_common.logger("this.delegate");
      Cl_common.logger(this.delegate);

      //レスポンス処理
      //-------
      if (1 == currentPage) {
        let vgt: any = this.$refs.vgt;
        vgt.reset();
      }
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      this.delegate = new DtoItemsGetResImple();
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ファイルをダウンロードする
   */
  async dl() {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      // let dto = new DtoFileMastersGetReqImple();
      let dto = new DtoFileReportsGetReqImple();
      dto.type = "SyouhinList";
      dto.itemManagNumber = this.domItemManagNumber;
      dto.itemName = this.domItemName;
      if (0 < this.domItemType.length) {
        dto.itemType = this.domItemType.join(",");
      }
      if (0 < this.domOwner.length) {
        dto.itemOwner = this.domOwner.join(",");
      }
      dto.itemArea = this.domArea;

      Cl_common.logger("domTerm:" + this.domTerm);
      if (null != this.domTerm && "" != this.domTerm) {
        dto.itemTerm = this.domTerm;
      }

      //API実行
      //-------
      const res = await Cl_filereport.get(dto);

      if (0 == res.outputqueue_code.length) {
        throw new Error("出力失敗");
      }

      let timeLimit = Date.now() + 15 * 60 * 1000; // 15分
      let now = Date.now();
      let dto2 = new DtoOutputqueuesGetReqImple();
      dto2.c_outputqueue_code = res.outputqueue_code;
      let res2 = new DtoOutputqueuesGetResImple();
      res2 = await Cl_outputqueue.get(dto2);
      while (now < timeLimit) {
        await new Promise((resolve) => setTimeout(resolve, 10000)); // 10秒待つ

        now = Date.now();

        res2 = await Cl_outputqueue.get(dto2);
        if (res2.c_outputqueue_flag_end) {
          break;
        }
      }

      //レスポンス処理
      //-------
      if (res2.c_outputqueue_flag_success) {
        location.href = res2.url;
      } else {
        throw new Error("出力失敗");
      }
      //window.open(res.url, "_blank");
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }
  /**
   * ページ更新時の処理
   * @param params
   */
  async onPageChange(params: any) {
    //テーブル更新処理
    await this.getlist(params.currentPage);
  }
  /**
   * ソート変更時の処理
   * @param params
   */
  async onSortChange(params: any) {
    //テーブル更新処理
  }
  /**
   * 画面遷移用
   * @param url
   * @param event
   */
  async goTo(url: string, event?: MouseEvent) {
    Cl_common.goTo(url, this.$router, event);
  }
  async moveDet(id: string) {
    //詳細ページに移動
    this.$router.push("/syouhinedit?id=" + id);
  }

  /**
   * 商品追加
   */
  async add(type: number) {
    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementsByClassName("panel-container");
    Array.from(panel).map((element: Element) => {
      element.classList.add("enable-loader");
    });

    let res;

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoItemsPostReq();
      dto.c_item_type = type;

      //API実行
      //-------
      res = await Cl_item.post(dto);
      console.log(res);

      //レスポンス処理
      //-------
      this.moveDet(String(res.c_item_id));
    } catch (error) {
      //モーダルのメッセージを設定
      this.message4ModalErrorConf = "エラーが発生しました";
      //モーダル表示
      const modal: any = this.$refs.Mo_modal_errorConf;
      modal.showModal();

      //エラー発生で一覧を初期化
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      Array.from(panel).map((element: Element) => {
        element.classList.remove("enable-loader");
      });
    }
  }

  //----------------------
  //モーダル用メソッド
  //----------------------
  /**
   * 商品種別選択用モーダル表示
   */
  async showModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.showModal();
  }
  /**
   * 商品種別選択用モーダル閉じる
   */
  async closeModal(): Promise<void> {
    const modalComp: any = this.$refs.Mo_modal_selectType;
    modalComp.closeModal();
  }
  async closeModal4ErrorConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_errorConf;
    modal.closeModal();
  }
}
