import { DtoApiPager } from "./abst/DtoApiPager";

/**
 * 列挙体　処理Typeの列挙体
 */
export enum e_aggTypes {
  none_none,
  badge_0,
  summary_graph_100,
  summary_list_200,
  client_graph_300,
  client_genre_400,
  client_ranking_500,
  client_list_600,
}

export class DtoAggregatesGetReq {
  // 処理タイプ
  //-------
  public aggType: e_aggTypes = e_aggTypes.none_none;
  //-------------------
  //顧客タブ用
  //-------------------
  //条件指定
  //-------
  /**
   * 売上先
   */
  public clients: string | null = null;
  /**
   * 業種
   */
  public genres: string | null = null;

  public IsValidImple(chkPager: boolean): string[] {
    let res: string[] = new Array();

    return res;
  }
}
