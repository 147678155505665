/**
* 列挙体　テーブル名
*/
export enum e_tables {t_agg_client,t_alert,t_attendance,t_bank,t_branch,t_claim,t_claim_det,t_client,t_condition,t_doc,t_docfile,t_file,t_goal,t_history,t_holiday,t_holisticlock,t_item,t_lock,t_message,t_outputqueue,t_pro2doc,t_project,t_project_tmp,t_request,t_request_addchg,t_request_aprv,t_request_biztrip,t_request_caruse,t_request_costsacct,t_request_entmt,t_request_expenses,t_request_holiday,t_request_loa,t_request_overtime,t_request_remote,t_request_rwdacct,t_request_tmppay,t_sales,t_sales_tmp,t_setting,t_staff,t_supplier,t_supply,t_supply_tmp,t_variation,v_agg4client,v_agg_sales,v_agg_sales4order,v_agg_supply,v_agg_supply4order,v_diagnosis_1,v_diagnosis_2,v_diagnosis_3,v_diagnosis_4,v_diagnosis_5,v_diagnosis_6,v_diagnosis_7,v_diagnosis_8,v_doc4det,v_ledger9depositSchedule,v_sales4project,v_staff4attendance,v_supply4project,}/**
* 列挙体　カラム　t_agg_client
 */
export enum e_t_agg_client {c_agg_client_id,c_client_id,c_agg_client_year,c_agg_client_month,c_agg_client_amount_sales,c_agg_client_amount_supply,c_agg_client_amount_profit,c_agg_client_ratio_profit,c_agg_client_type,c_agg_client_reg_user,c_agg_client_reg_at,c_agg_client_upd_user,c_agg_client_upd_at,}/**
* 列挙体　カラム　t_alert
 */
export enum e_t_alert {c_alert_id,c_staff_id,c_alert_type,c_alert_cont,c_alert_url_link,c_alert_limit,c_alert_flag_read,c_alert_flag_mail,c_alert_marker,c_alert_reg_user,c_alert_reg_at,c_alert_upd_user,c_alert_upd_at,}/**
* 列挙体　カラム　t_attendance
 */
export enum e_t_attendance {c_attendance_id,c_staff_id,c_attendance_date,c_attendance_time_work,c_attendance_lat_work,c_attendance_lng_work,c_attendance_time_goout_start,c_attendance_lat_goout_start,c_attendance_lng_goout_start,c_attendance_time_goout_end,c_attendance_lat_goout_end,c_attendance_lng_goout_end,c_attendance_time_leaving,c_attendance_lat_leaving,c_attendance_lng_leaving,c_attendance_ot1_aftertime,c_attendance_ot2_nighttime,c_attendance_ot3_hd_aftertime,c_attendance_ot4_hd_nighttime,c_attendance_ot5_sp_aftertime,c_attendance_ot6_sp_nighttime,c_attendance_remarks,c_attendance_correct_time_work,c_attendance_correct_time_goout_start,c_attendance_correct_time_goout_end,c_attendance_correct_time_leaving,c_attendance_correct_time_rest,c_attendance_flag_upd_work,c_attendance_flag_upd_goout_start,c_attendance_flag_upd_goout_end,c_attendance_flag_upd_leaving,c_attendance_flag_upd_aftertime,c_attendance_flag_upd_nighttime,c_attendance_flag_upd_hd_aftertime,c_attendance_flag_upd_hd_nighttime,c_attendance_flag_upd_sp_aftertime,c_attendance_flag_upd_sp_nighttime,c_attendance_reg_user,c_attendance_reg_at,c_attendance_upd_user,c_attendance_upd_at,}/**
* 列挙体　カラム　t_bank
 */
export enum e_t_bank {c_bank_id,c_bank_name,c_bank_branch_name,c_bank_type,c_bank_number,c_bank_flag_del,c_bank_reg_user,c_bank_reg_at,c_bank_upd_user,c_bank_upd_at,}/**
* 列挙体　カラム　t_branch
 */
export enum e_t_branch {c_branch_id,c_branch_name,c_branch_tel,c_branch_fax,c_branch_zip,c_branch_address_1,c_branch_address_2,c_branch_flag_del,c_branch_reg_user,c_branch_reg_at,c_branch_upd_user,c_branch_upd_at,}/**
* 列挙体　カラム　t_claim
 */
export enum e_t_claim {c_claim_id,c_project_id,c_claim_number,c_claim_datetime,c_client_id,c_staff_id,c_claim_title_1,c_claim_title_2,c_claim_sponsor_name,c_bank_id_1,c_bank_id_2,c_bank_id_3,c_claim_subject,c_claim_breakdown,c_claim_paylimit,c_claim_flag_delivery,c_claim_remarks,c_claim_status,c_claim_flag_sales,c_claim_subtotal,c_claim_total,c_claim_depo_datetime,c_claim_flag_del,c_claim_reg_user,c_claim_reg_at,c_claim_upd_user,c_claim_upd_at,}/**
* 列挙体　カラム　t_claim_det
 */
export enum e_t_claim_det {c_claim_det_id,c_claim_id,c_claim_rownum,c_claim_det_item,c_claim_det_quantity,c_claim_det_unitprice,c_claim_det_price,c_claim_det_taxrate,c_claim_det_price_sum,c_claim_rounding,c_claim_det_reg_user,c_claim_det_reg_at,c_claim_det_upd_user,c_claim_det_upd_at,}/**
* 列挙体　カラム　t_client
 */
export enum e_t_client {c_client_id,c_client_name,c_client_name_en,c_client_name_pt,c_client_company_type,c_client_flag_company_signage_posi,c_client_tel,c_client_fax,c_client_zip,c_client_address_1,c_client_address_2,c_client_flag_claim_addon,c_client_claim_zip,c_client_claim_address_1,c_client_claim_address_2,c_client_paysite,c_client_staff_name,c_client_staff_name_pt,c_client_depart,c_client_post,c_client_flag_post_signag_posi,c_client_remarks,c_client_flag_del,c_client_show_order,c_client_claim_language,c_client_genre,c_client_flag_type_ad,c_client_flag_type_mfg,c_client_flag_type_print,c_client_flag_type_event,c_client_flag_type_comm,c_client_flag_type_fashion,c_client_flag_type_beauty,c_client_flag_type_cosmetic,c_client_flag_type_device,c_client_flag_type_appliance,c_client_flag_type_caretc,c_client_flag_type_music,c_client_flag_type_sport,c_client_flag_type_trip,c_client_flag_type_game,c_client_flag_type_book,c_client_flag_type_movie,c_client_flag_type_food,c_client_flag_type_drink,c_client_flag_type_eatout,c_client_flag_type_transpt,c_client_flag_type_edu,c_client_flag_type_medical,c_client_flag_type_stock,c_client_flag_type_realty,c_client_flag_type_insure,c_client_flag_type_cfpa,c_client_flag_type_govtofc,c_client_flag_type_politics,c_client_flag_type_matching,c_client_flag_type_gamble,c_client_flag_type_others,c_client_reg_user,c_client_reg_at,c_client_upd_user,c_client_upd_at,}/**
* 列挙体　カラム　t_condition
 */
export enum e_t_condition {c_condition_id,c_variation_id,c_supplier_id,c_condition_name,c_condition_type,c_condition_price,c_condition_flag_del,c_condition_remarks,c_condition_reg_user,c_condition_reg_at,c_condition_upd_user,c_condition_upd_at,}/**
* 列挙体　カラム　t_doc
 */
export enum e_t_doc {c_doc_id,c_staff_id,c_client_id,c_supplier_id,c_claim_id,c_request_expenses_id,c_doc_name,c_doc_managtype,c_doc_type,c_doc_price,c_doc_date,c_doc_customer,c_doc_customerstaff,c_staff_id4project,c_doc_remarks,c_doc_flag_issuer,c_doc_search,c_doc_flag_enab,c_doc_flag_reserve,c_doc_flag_del,c_doc_reg_user,c_doc_reg_at,c_doc_upd_user,c_doc_upd_at,}/**
* 列挙体　カラム　t_docfile
 */
export enum e_t_docfile {c_docfile_id,c_doc_id,c_staff_id,c_docfile_ver,c_docfile_imgpath,c_docfile_filename,c_docfile_filetype,c_docfile_size,c_docfile_dig,c_docfile_ocrtxt,c_docfile_flag_del,c_docfile_reg_user,c_docfile_reg_at,c_docfile_upd_user,c_docfile_upd_at,}/**
* 列挙体　カラム　t_file
 */
export enum e_t_file {c_file_id,c_project_id,c_file_order,c_file_name,c_file_path,c_file_reg_user,c_file_reg_at,c_file_upd_user,c_file_upd_at,}/**
* 列挙体　カラム　t_goal
 */
export enum e_t_goal {c_goal_id,c_goal_year,c_goal_earnings_1,c_goal_gross_1,c_goal_earnings_2,c_goal_gross_2,c_goal_earnings_3,c_goal_gross_3,c_goal_earnings_4,c_goal_gross_4,c_goal_earnings_5,c_goal_gross_5,c_goal_earnings_6,c_goal_gross_6,c_goal_earnings_7,c_goal_gross_7,c_goal_earnings_8,c_goal_gross_8,c_goal_earnings_9,c_goal_gross_9,c_goal_earnings_10,c_goal_gross_10,c_goal_earnings_11,c_goal_gross_11,c_goal_earnings_12,c_goal_gross_12,c_goal_flag_del,c_goal_reg_user,c_goal_reg_at,c_goal_upd_user,c_goal_upd_at,}/**
* 列挙体　カラム　t_history
 */
export enum e_t_history {c_history_id,c_staff_id,c_doc_id,c_history_datetime,c_history_title,c_history_det,c_history_logstream,c_history_eventid,c_history_reg_user,c_history_reg_at,c_history_upd_user,c_history_upd_at,}/**
* 列挙体　カラム　t_holiday
 */
export enum e_t_holiday {c_holiday_id,c_holiday_date,c_holiday_name,c_holiday_reg_user,c_holiday_reg_at,c_holiday_upd_user,c_holiday_upd_at,}/**
* 列挙体　カラム　t_holisticlock
 */
export enum e_t_holisticlock {c_holisticlock_id,c_staff_id,c_holisticlock_date,c_holisticlock_remarks,c_holisticlock_reg_user,c_holisticlock_reg_at,c_holisticlock_upd_user,c_holisticlock_upd_at,}/**
* 列挙体　カラム　t_item
 */
export enum e_t_item {c_item_id,c_item_type,c_item_term,c_item_insurance,c_item_manag_name,c_item_name,c_item_name_pt,c_item_owner,c_item_area,c_item_area_type,c_item_address,c_item_route,c_item_note,c_item_remarks,c_item_url_photo,c_item_info,c_item_url_data_1,c_item_url_data_2,c_item_url_data_3,c_item_flag_del,c_item_reg_user,c_item_reg_at,c_item_upd_user,c_item_upd_at,}/**
* 列挙体　カラム　t_lock
 */
export enum e_t_lock {c_lock_id,c_staff_id,c_lock_month,c_lock_reg_user,c_lock_reg_at,c_lock_upd_user,c_lock_upd_at,}/**
* 列挙体　カラム　t_message
 */
export enum e_t_message {c_message_id,c_staff_id,c_message_start,c_message_end,c_message_cont,c_message_flag_del,c_message_reg_user,c_message_reg_at,c_message_upd_user,c_message_upd_at,}/**
* 列挙体　カラム　t_outputqueue
 */
export enum e_t_outputqueue {c_outputqueue_id,c_outputqueue_date_start,c_outputqueue_date_end,c_outputqueue_filename,c_outputqueue_code,c_outputqueue_flag_end,c_outputqueue_flag_success,c_outputqueue_flag_failure,c_outputqueue_message,c_outputqueue_reg_user,c_outputqueue_reg_at,c_outputqueue_upd_user,c_outputqueue_upd_at,}/**
* 列挙体　カラム　t_pro2doc
 */
export enum e_t_pro2doc {c_pro2doc_id,c_project_id,c_doc_id,c_pro2doc_reg_user,c_pro2doc_reg_at,c_pro2doc_upd_user,c_pro2doc_upd_at,}/**
* 列挙体　カラム　t_project
 */
export enum e_t_project {c_project_id,c_client_id,c_staff_id,c_project_number,c_project_name,c_project_cont,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_flag_del,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,}/**
* 列挙体　カラム　t_project_tmp
 */
export enum e_t_project_tmp {c_project_id,c_client_id,c_staff_id,c_project_number,c_project_name,c_project_date_start,c_project_date_end,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_flag_del,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,}/**
* 列挙体　カラム　t_request
 */
export enum e_t_request {c_request_id,c_staff_id,c_request_type,c_request_datetime,c_request_status,c_request_name,c_request_1_approval,c_request_1_approval_datetime,c_request_1_approval_status,c_request_2_approval,c_request_2_approval_datetime,c_request_2_approval_status,c_request_3_approval,c_request_3_approval_datetime,c_request_3_approval_status,c_request_flag_del,c_request_reg_user,c_request_reg_at,c_request_upd_user,c_request_upd_at,}/**
* 列挙体　カラム　t_request_addchg
 */
export enum e_t_request_addchg {c_request_addchg_id,c_request_id,c_request_addchg_type,c_request_addchg_type_oth,c_request_addchg_start,c_request_addchg_cr_zip,c_request_addchg_cr_add,c_request_addchg_cr_tel,c_request_addchg_new_zip,c_request_addchg_new_add,c_request_addchg_new_tel,c_request_addchg_route_list,c_request_addchg_reg_user,c_request_addchg_reg_at,c_request_addchg_upd_user,c_request_addchg_upd_at,}/**
* 列挙体　カラム　t_request_aprv
 */
export enum e_t_request_aprv {c_request_aprv_id,c_request_id,c_request_aprv_deadline,c_request_aprv_address_result,c_request_aprv_name,c_request_aprv_cont,c_request_aprv_payment,c_request_aprv_1_cont,c_request_aprv_2_cont,c_request_aprv_3_cont,c_request_aprv_result,c_request_aprv_fin_date,c_request_aprv_number,c_request_aprv_reg_user,c_request_aprv_reg_at,c_request_aprv_upd_user,c_request_aprv_upd_at,}/**
* 列挙体　カラム　t_request_biztrip
 */
export enum e_t_request_biztrip {c_request_biztrip_id,c_request_id,c_request_biztrip_etd,c_request_biztrip_return,c_request_biztrip_dest,c_request_biztrip_stay,c_request_biztrip_companion,c_request_biztrip_cont,c_request_biztrip_reg_user,c_request_biztrip_reg_at,c_request_biztrip_upd_user,c_request_biztrip_upd_at,}/**
* 列挙体　カラム　t_request_caruse
 */
export enum e_t_request_caruse {c_request_caruse_id,c_request_id,c_request_caruse_lic_date,c_request_caruse_start,c_request_caruse_end,c_request_caruse_pax,c_request_caruse_pax_1_name,c_request_caruse_pax_1_com,c_request_caruse_pax_2_name,c_request_caruse_pax_2_com,c_request_caruse_pax_3_name,c_request_caruse_pax_3_com,c_request_caruse_pax_4_name,c_request_caruse_pax_4_com,c_request_caruse_dest,c_request_caruse_cont,c_request_caruse_reg_user,c_request_caruse_reg_at,c_request_caruse_upd_user,c_request_caruse_upd_at,}/**
* 列挙体　カラム　t_request_costsacct
 */
export enum e_t_request_costsacct {c_request_costsacct_id,c_request_id,c_request_costsacct_type,c_request_costsacct_flag_rwdacct_match,c_request_costsacct_bank_name,c_request_costsacct_bank_type,c_request_costsacct_bank_branch_name,c_request_costsacct_bank_number,c_request_costsacct_bank_account_name,c_request_costsacct_reg_user,c_request_costsacct_reg_at,c_request_costsacct_upd_user,c_request_costsacct_upd_at,}/**
* 列挙体　カラム　t_request_entmt
 */
export enum e_t_request_entmt {c_request_entmt_id,c_request_id,c_request_entmt_date,c_request_entmt_dest,c_request_entmt_companion,c_request_entmt_bud,c_request_entmt_cont,c_request_entmt_reg_user,c_request_entmt_reg_at,c_request_entmt_upd_user,c_request_entmt_upd_at,}/**
* 列挙体　カラム　t_request_expenses
 */
export enum e_t_request_expenses {c_request_expenses_id,c_request_id,c_request_expenses_date_draft,c_request_expenses_type,c_request_expenses_tmppay_price,c_request_expenses_total,c_request_expenses_list,c_request_expenses_reg_user,c_request_expenses_reg_at,c_request_expenses_upd_user,c_request_expenses_upd_at,}/**
* 列挙体　カラム　t_request_holiday
 */
export enum e_t_request_holiday {c_request_holiday_id,c_request_id,c_request_holiday_start,c_request_holiday_end,c_request_holiday_part,c_request_holiday_part_type,c_request_holiday_type,c_request_holiday_type_oth,c_request_holiday_cont,c_request_holiday_remarks,c_request_holiday_reg_user,c_request_holiday_reg_at,c_request_holiday_upd_user,c_request_holiday_upd_at,}/**
* 列挙体　カラム　t_request_loa
 */
export enum e_t_request_loa {c_request_loa_id,c_request_id,c_request_loa_date_start,c_request_loa_date_end,c_request_loa_cont,c_request_loa_tel,c_request_loa_reg_user,c_request_loa_reg_at,c_request_loa_upd_user,c_request_loa_upd_at,}/**
* 列挙体　カラム　t_request_overtime
 */
export enum e_t_request_overtime {c_request_overtime_id,c_request_id,c_request_overtime_type,c_request_overtime_ot_date,c_request_overtime_ot_datetime_start_exp,c_request_overtime_ot_datetime_end_exp,c_request_overtime_ot_location,c_request_overtime_hol_date,c_request_overtime_hol_datetime_start_exp,c_request_overtime_hol_datetime_end_exp,c_request_overtime_hol_location,c_request_overtime_compday_all,c_request_overtime_compday_part,c_request_overtime_compday_part_start_time,c_request_overtime_compday_part_end_time,c_request_overtime_cont,c_request_overtime_reg_user,c_request_overtime_reg_at,c_request_overtime_upd_user,c_request_overtime_upd_at,}/**
* 列挙体　カラム　t_request_remote
 */
export enum e_t_request_remote {c_request_remote_id,c_request_id,c_request_remote_date,c_request_remote_datetime_start_exp,c_request_remote_datetime_end_exp,c_request_remote_location,c_request_remote_reason,c_request_remote_cont,c_request_remote_reg_user,c_request_remote_reg_at,c_request_remote_upd_user,c_request_remote_upd_at,}/**
* 列挙体　カラム　t_request_rwdacct
 */
export enum e_t_request_rwdacct {c_request_rwdacct_id,c_request_id,c_request_rwdacct_type,c_request_rwdacct_bank_name,c_request_rwdacct_bank_type,c_request_rwdacct_bank_branch_name,c_request_rwdacct_bank_number,c_request_rwdacct_bank_account_name,c_request_rwdacct_reg_user,c_request_rwdacct_reg_at,c_request_rwdacct_upd_user,c_request_rwdacct_upd_at,}/**
* 列挙体　カラム　t_request_tmppay
 */
export enum e_t_request_tmppay {c_request_tmppay_id,c_request_id,c_request_tmppay_price,c_request_tmppay_date,c_request_tmppay_date_exp_payment,c_request_tmppay_cont,c_request_tmppay_reg_user,c_request_tmppay_reg_at,c_request_tmppay_upd_user,c_request_tmppay_upd_at,}/**
* 列挙体　カラム　t_sales
 */
export enum e_t_sales {c_sales_id,c_project_id,c_variation_id,c_sales_rownum,c_claim_id,c_sales_claimnum_tmp,c_sales_issue,c_sales_month,c_sales_subject,c_sales_term,c_sales_item,c_sales_count,c_sales_date_start,c_sales_date_end,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_flag_alert,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,}/**
* 列挙体　カラム　t_sales_tmp
 */
export enum e_t_sales_tmp {c_sales_id,c_project_id,c_sales_rownum,c_claim_id,c_sales_issue,c_sales_month,c_sales_item,c_sales_subject,c_sales_breakdown,c_client_id,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,}/**
* 列挙体　カラム　t_setting
 */
export enum e_t_setting {c_setting_id,c_setting_key,c_setting_val,c_setting_reg_user,c_setting_reg_at,c_setting_upd_user,c_setting_upd_at,}/**
* 列挙体　カラム　t_staff
 */
export enum e_t_staff {c_staff_id,c_staff_number,c_staff_name,c_staff_name_en,c_staff_name_pt,c_staff_mail,c_branch_id,c_staff_depart,c_staff_elder_user_id_1,c_staff_elder_user_flag_mail_1,c_staff_elder_user_id_2,c_staff_elder_user_flag_mail_2,c_staff_elder_user_id_3,c_staff_elder_user_flag_mail_3,c_staff_elder_user_id_4,c_staff_elder_user_flag_mail_4,c_staff_elder_user_id_5,c_staff_elder_user_flag_mail_5,c_staff_login_id,c_staff_login_pass,c_staff_auth_type,c_staff_flag_auth_claim,c_staff_flag_auth_claimnum_chg,c_staff_flag_auth_listexprt,c_staff_flag_auth_report,c_staff_show_order,c_staff_show_order_attendandce,c_staff_reportchk_user_id_1,c_staff_reportchk_user_id_2,c_staff_reportchk_user_id_3,c_staff_reportchk_user_id_4,c_staff_reportchk_user_id_5,c_staff_application_staff_id_1,c_staff_application_staff_id_2,c_staff_application_staff_id_3,c_staff_individual_staff_id_1,c_staff_individual_staff_id_2,c_staff_individual_staff_id_3,c_staff_alert_mail,c_staff_flag_mail_claim_request,c_staff_flag_mail_claim_approval,c_staff_flag_mail_claim_return,c_staff_flag_mail_contract_period_sales,c_staff_flag_mail_contract_period_supply,c_staff_flag_mail_request_request,c_staff_flag_mail_request_approval,c_staff_flag_mail_request_return,c_staff_flag_mail_request_withdrawal,c_staff_flag_insurance,c_staff_flag_del,c_staff_reg_user,c_staff_reg_at,c_staff_upd_user,c_staff_upd_at,}/**
* 列挙体　カラム　t_supplier
 */
export enum e_t_supplier {c_supplier_id,c_supplier_name,c_supplier_name_pt,c_supplier_company_type,c_supplier_flag_company_signage_posi,c_supplier_tel,c_supplier_fax,c_supplier_zip,c_supplier_address_1,c_supplier_address_2,c_supplier_paysite,c_supplier_staff_name,c_supplier_staff_name_pt,c_supplier_remarks,c_supplier_flag_del,c_supplier_show_order,c_supplier_reg_user,c_supplier_reg_at,c_supplier_upd_user,c_supplier_upd_at,}/**
* 列挙体　カラム　t_supply
 */
export enum e_t_supply {c_supply_id,c_project_id,c_variation_id,c_pro2doc_id,c_supply_rownum,c_claim_id,c_supply_claimnum_tmp,c_supply_month,c_supply_subject,c_supply_item,c_supply_count,c_supply_date_start,c_supply_date_end,c_supplier_id,c_supply_price,c_supply_taxrate,c_supply_flag_chkclaim,c_supply_date_exp_payment,c_supply_flag_paid,c_supply_remarks,c_supply_flag_lock,c_supply_flag_insurance,c_supply_flag_alert,c_supply_reg_user,c_supply_reg_at,c_supply_upd_user,c_supply_upd_at,}/**
* 列挙体　カラム　t_supply_tmp
 */
export enum e_t_supply_tmp {c_supply_id,c_project_id,c_supply_rownum,c_claim_id,c_supply_month,c_supply_item,c_supply_subject,c_supply_breakdown,c_supplier_id,c_supply_price,c_supply_taxrate,c_supply_date_exp_payment,c_supply_flag_paid,c_supply_remarks,c_supply_flag_lock,c_supply_reg_user,c_supply_reg_at,c_supply_upd_user,c_supply_upd_at,}/**
* 列挙体　カラム　t_variation
 */
export enum e_t_variation {c_variation_id,c_item_id,c_variation_manag_name,c_variation_name,c_variation_size,c_variation_specification,c_variation_type,c_variation_time_start,c_variation_time_end,c_variation_term,c_variation_term_type,c_variation_price,c_variation_price_type,c_variation_margin_sales,c_variation_margin_supply,c_variation_remarks,c_variation_flag_del,c_variation_reg_user,c_variation_reg_at,c_variation_upd_user,c_variation_upd_at,}/**
* 列挙体　カラム　v_agg4client
 */
export enum e_v_agg4client {c_agg_client_id,c_client_id,c_agg_client_amount_sales,c_agg_client_month,c_agg_client_year,c_agg_client_amount_supply,c_agg_client_amount_profit,c_agg_client_ratio_profit,c_agg_client_reg_user,c_agg_client_reg_at,c_agg_client_upd_user,c_agg_client_upd_at,c_client_name,c_client_name_en,c_client_name_pt,c_client_company_type,c_client_flag_company_signage_posi,c_client_tel,c_client_fax,c_client_zip,c_client_address_1,c_client_address_2,c_client_flag_claim_addon,c_client_claim_zip,c_client_claim_address_1,c_client_claim_address_2,c_client_paysite,c_client_staff_name,c_client_staff_name_pt,c_client_depart,c_client_post,c_client_flag_post_signag_posi,c_client_remarks,c_client_flag_del,c_client_show_order,c_client_claim_language,c_client_genre,c_client_flag_type_ad,c_client_flag_type_mfg,c_client_flag_type_event,c_client_flag_type_print,c_client_flag_type_comm,c_client_flag_type_fashion,c_client_flag_type_cosmetic,c_client_flag_type_beauty,c_client_flag_type_device,c_client_flag_type_appliance,c_client_flag_type_caretc,c_client_flag_type_music,c_client_flag_type_sport,c_client_flag_type_trip,c_client_flag_type_game,c_client_flag_type_book,c_client_flag_type_movie,c_client_flag_type_food,c_client_flag_type_drink,c_client_flag_type_eatout,c_client_flag_type_transpt,c_client_flag_type_edu,c_client_flag_type_medical,c_client_flag_type_stock,c_client_flag_type_realty,c_client_flag_type_insure,c_client_flag_type_cfpa,c_client_flag_type_govtofc,c_client_flag_type_politics,c_client_flag_type_matching,c_client_flag_type_gamble,c_client_flag_type_others,c_client_reg_user,c_client_reg_at,c_client_upd_user,c_client_upd_at,c_agg_client_type,}/**
* 列挙体　カラム　v_agg_sales
 */
export enum e_v_agg_sales {c_project_flag_del,c_claim_flag_del,c_claim_id,c_claim_status,c_sales_id,c_project_id,c_variation_id,c_sales_rownum,c_sales_claimnum_tmp,c_sales_issue,c_sales_term,c_sales_month,c_sales_item,c_sales_count,c_sales_date_start,c_sales_date_end,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_flag_alert,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,c_project_id4claim,c_claim_number,c_claim_datetime,c_client_id,c_staff_id,c_claim_title_1,c_claim_sponsor_name,c_claim_title_2,c_bank_id_1,c_bank_id_2,c_claim_subject,c_bank_id_3,c_claim_breakdown,c_claim_paylimit,c_claim_flag_delivery,c_claim_remarks,c_claim_flag_sales,c_claim_subtotal,c_claim_total,c_claim_depo_datetime,c_claim_reg_user,c_claim_reg_at,c_claim_upd_user,c_claim_upd_at,c_client_id4project,c_staff_id4project,c_project_number,c_project_name,c_project_cont,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,c_item_id,c_variation_manag_name,c_variation_name,c_variation_size,c_variation_specification,c_variation_type,c_variation_time_start,c_variation_time_end,c_variation_term,c_variation_term_type,c_variation_price_type,c_variation_price,c_variation_margin_sales,c_variation_margin_supply,c_variation_remarks,c_variation_flag_del,c_variation_reg_user,c_variation_reg_at,c_variation_upd_user,c_variation_upd_at,c_item_type,c_item_term,c_item_insurance,c_item_manag_name,c_item_name,c_item_name_pt,c_item_owner,c_item_area,c_item_area_type,c_item_address,c_item_route,c_item_note,c_item_remarks,c_item_url_photo,c_item_info,c_item_url_data_2,c_item_url_data_1,c_item_url_data_3,c_item_flag_del,c_item_reg_user,c_item_reg_at,c_item_upd_user,c_item_upd_at,c_sales_subject,}/**
* 列挙体　カラム　v_agg_sales4order
 */
export enum e_v_agg_sales4order {c_project_flag_del,c_claim_id,c_sales_id,c_project_id,c_variation_id,c_sales_rownum,c_sales_claimnum_tmp,c_sales_issue,c_sales_term,c_sales_month,c_sales_item,c_sales_count,c_sales_date_start,c_sales_date_end,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_flag_alert,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,c_client_id4project,c_staff_id4project,c_project_number,c_project_name,c_project_cont,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,c_item_id,c_variation_manag_name,c_variation_name,c_variation_size,c_variation_specification,c_variation_type,c_variation_time_start,c_variation_time_end,c_variation_term,c_variation_term_type,c_variation_price_type,c_variation_price,c_variation_margin_sales,c_variation_margin_supply,c_variation_remarks,c_variation_flag_del,c_variation_reg_user,c_variation_reg_at,c_variation_upd_user,c_variation_upd_at,c_item_type,c_item_term,c_item_insurance,c_item_manag_name,c_item_name,c_item_name_pt,c_item_owner,c_item_area,c_item_area_type,c_item_address,c_item_route,c_item_note,c_item_remarks,c_item_url_photo,c_item_info,c_item_url_data_2,c_item_url_data_1,c_item_url_data_3,c_item_flag_del,c_item_reg_user,c_item_reg_at,c_item_upd_user,c_item_upd_at,c_sales_subject,}/**
* 列挙体　カラム　v_agg_supply
 */
export enum e_v_agg_supply {c_project_flag_del,c_claim_flag_del,c_claim_id,c_claim_status,c_supply_id,c_project_id,c_variation_id,c_pro2doc_id,c_supply_rownum,c_supply_month,c_supply_claimnum_tmp,c_supply_item,c_supply_count,c_supply_date_start,c_supply_date_end,c_supplier_id,c_supply_price,c_supply_taxrate,c_supply_flag_chkclaim,c_supply_date_exp_payment,c_supply_flag_paid,c_supply_remarks,c_supply_flag_lock,c_supply_flag_insurance,c_supply_flag_alert,c_supply_reg_user,c_supply_reg_at,c_supply_upd_user,c_supply_upd_at,c_client_id,c_staff_id,c_project_number,c_project_name,c_project_cont,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,c_project_id4claim,c_claim_number,c_claim_datetime,c_client_id4claim,c_staff_id4claim,c_claim_title_1,c_claim_title_2,c_claim_sponsor_name,c_bank_id_1,c_bank_id_2,c_bank_id_3,c_claim_subject,c_claim_breakdown,c_claim_paylimit,c_claim_flag_delivery,c_claim_remarks,c_claim_flag_sales,c_claim_total,c_claim_subtotal,c_claim_depo_datetime,c_claim_reg_user,c_claim_reg_at,c_claim_upd_user,c_claim_upd_at,c_item_id,c_variation_manag_name,c_variation_name,c_variation_size,c_variation_specification,c_variation_type,c_variation_term,c_variation_time_start,c_variation_time_end,c_variation_term_type,c_variation_price,c_variation_price_type,c_variation_margin_sales,c_variation_margin_supply,c_variation_remarks,c_variation_flag_del,c_variation_reg_user,c_variation_reg_at,c_variation_upd_user,c_variation_upd_at,c_item_type,c_item_term,c_item_insurance,c_item_manag_name,c_item_name_pt,c_item_name,c_item_owner,c_item_area,c_item_area_type,c_item_address,c_item_route,c_item_remarks,c_item_note,c_item_url_photo,c_item_info,c_item_url_data_1,c_item_url_data_2,c_item_url_data_3,c_item_flag_del,c_item_reg_user,c_item_reg_at,c_item_upd_user,c_item_upd_at,c_supply_subject,}/**
* 列挙体　カラム　v_agg_supply4order
 */
export enum e_v_agg_supply4order {c_project_flag_del,c_claim_id,c_supply_id,c_project_id,c_variation_id,c_pro2doc_id,c_supply_rownum,c_supply_month,c_supply_claimnum_tmp,c_supply_item,c_supply_count,c_supply_date_start,c_supply_date_end,c_supplier_id,c_supply_price,c_supply_taxrate,c_supply_flag_chkclaim,c_supply_date_exp_payment,c_supply_flag_paid,c_supply_remarks,c_supply_flag_lock,c_supply_flag_insurance,c_supply_flag_alert,c_supply_reg_user,c_supply_reg_at,c_supply_upd_user,c_supply_upd_at,c_client_id,c_staff_id,c_project_number,c_project_name,c_project_cont,c_project_alert,c_project_type,c_project_sponsor_name,c_project_probability,c_project_billing_type,c_project_remarks,c_project_reg_user,c_project_reg_at,c_project_upd_user,c_project_upd_at,c_item_id,c_variation_manag_name,c_variation_name,c_variation_size,c_variation_specification,c_variation_type,c_variation_term,c_variation_time_start,c_variation_time_end,c_variation_term_type,c_variation_price,c_variation_price_type,c_variation_margin_sales,c_variation_margin_supply,c_variation_remarks,c_variation_flag_del,c_variation_reg_user,c_variation_reg_at,c_variation_upd_user,c_variation_upd_at,c_item_type,c_item_term,c_item_insurance,c_item_manag_name,c_item_name_pt,c_item_name,c_item_owner,c_item_area,c_item_area_type,c_item_address,c_item_route,c_item_remarks,c_item_note,c_item_url_photo,c_item_info,c_item_url_data_1,c_item_url_data_2,c_item_url_data_3,c_item_flag_del,c_item_reg_user,c_item_reg_at,c_item_upd_user,c_item_upd_at,c_supply_subject,}/**
* 列挙体　カラム　v_diagnosis_1
 */
export enum e_v_diagnosis_1 {c_claim_id,c_claim_number,c_claim_status,c_sales_id,c_sales_claimnum_tmp,c_claim_upd_at,c_claim_upd_user,c_claim_reg_at,c_claim_reg_user,}/**
* 列挙体　カラム　v_diagnosis_2
 */
export enum e_v_diagnosis_2 {c_project_id,c_claim_id,c_claim_status,c_sales_id,c_sales_claimnum_tmp,c_project_upd_at,c_project_upd_user,c_project_reg_at,c_project_reg_user,}/**
* 列挙体　カラム　v_diagnosis_3
 */
export enum e_v_diagnosis_3 {c_sales_id,c_sales_claimnum_tmp,v_project_ids,v_distinct_project_count,c_sales_upd_at,c_sales_upd_user,c_sales_reg_at,c_sales_reg_user,}/**
* 列挙体　カラム　v_diagnosis_4
 */
export enum e_v_diagnosis_4 {c_project_id,c_claim_id,c_claim_number,c_claim_subtotal,c_sales_price,v_claim_total,c_project_upd_at,c_project_upd_user,c_project_reg_at,c_project_reg_user,}/**
* 列挙体　カラム　v_diagnosis_5
 */
export enum e_v_diagnosis_5 {c_project_id,v_sales_ids,v_distinct_sales_count,c_project_upd_at,c_project_upd_user,c_project_reg_at,c_project_reg_user,}/**
* 列挙体　カラム　v_diagnosis_6
 */
export enum e_v_diagnosis_6 {c_claim_id,c_project_id,c_claim_number,c_claim_total,c_claim_status,c_claim_upd_at,c_claim_upd_user,c_claim_reg_at,c_claim_reg_user,}/**
* 列挙体　カラム　v_diagnosis_7
 */
export enum e_v_diagnosis_7 {c_project_id,c_sales_claimnum_tmp,c_sales_month,c_sales_date_exp_payment,c_sales_upd_at,c_sales_upd_user,c_sales_reg_at,c_sales_reg_user,}/**
* 列挙体　カラム　v_diagnosis_8
 */
export enum e_v_diagnosis_8 {c_project_id,c_supply_claimnum_tmp,c_supply_month,c_supply_date_exp_payment,c_supply_upd_at,c_supply_upd_user,c_supply_reg_at,c_supply_reg_user,}/**
* 列挙体　カラム　v_doc4det
 */
export enum e_v_doc4det {c_doc_id,c_staff_id,c_client_id,c_supplier_id,c_claim_id,c_request_expenses_id,c_doc_name,c_doc_type,c_doc_price,c_doc_customer,c_doc_date,c_doc_customerstaff,c_doc_flag_issuer,c_doc_search,c_doc_flag_enab,c_doc_flag_reserve,c_doc_flag_del,c_doc_reg_user,c_doc_reg_at,c_doc_upd_user,c_doc_upd_at,c_staff_number,c_staff_name,c_staff_name_pt,c_staff_flag_del,c_client_name,c_client_flag_del,c_client_show_order,c_supplier_name,c_supplier_flag_del,c_supplier_show_order,c_client_name4cliam,c_client_flag_del4cliam,c_client_show_order4cliam,c_project_id,c_claim_number,c_claim_datetime,c_client_id4cliam,c_staff_id4cliam,c_claim_subtotal,c_claim_total,c_claim_flag_sales,c_claim_status,c_claim_flag_del,c_request_id,c_request_expenses_date_draft,c_request_expenses_total,c_request_expenses_list,c_request_type,c_request_datetime,c_request_flag_del,c_staff_id4req,c_doc_managtype,c_staff_id4project,c_doc_remarks,c_staff_number4project,c_staff_name4project,c_staff_name_pt4project,c_staff_flag_del4project,}/**
* 列挙体　カラム　v_ledger9depositSchedule
 */
export enum e_v_ledger9depositSchedule {c_claim_id,c_claim_number,c_claim_datetime,c_claim_title_1,c_claim_sponsor_name,c_claim_subtotal,c_claim_total,c_claim_flag_sales,c_claim_status,c_claim_paylimit,c_claim_depo_datetime,c_sales_id,c_project_id,c_variation_id,c_sales_rownum,c_sales_claimnum_tmp,c_sales_issue,c_sales_month,c_sales_term,c_sales_item,c_sales_count,c_sales_date_start,c_sales_date_end,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_flag_alert,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,c_client_name,c_client_company_type,c_client_flag_company_signage_posi,c_project_number,c_staff_id,c_client_id,c_staff_number,c_staff_name,}/**
* 列挙体　カラム　v_sales4project
 */
export enum e_v_sales4project {c_sales_id,c_project_id,c_variation_id,c_sales_rownum,c_claim_id,c_sales_claimnum_tmp,c_sales_issue,c_sales_month,c_sales_term,c_sales_item,c_sales_count,c_sales_date_start,c_sales_date_end,c_sales_price,c_sales_taxrate,c_sales_date_exp_payment,c_sales_date_cmp_payment,c_sales_remarks,c_sales_flag_lock,c_sales_flag_alert,c_sales_reg_user,c_sales_reg_at,c_sales_upd_user,c_sales_upd_at,c_claim_number,c_item_id,c_variation_name,c_variation_manag_name,c_item_type,c_item_name,c_item_manag_name,c_claim_flag_del,c_sales_subject,}/**
* 列挙体　カラム　v_staff4attendance
 */
export enum e_v_staff4attendance {c_staff_id,c_staff_number,c_staff_name,c_staff_show_order_attendandce,c_branch_id,c_staff_depart,c_attendance_id,c_attendance_date,c_attendance_time_work,c_attendance_time_leaving,c_attendance_time_goout_start,c_attendance_time_goout_end,c_attendance_reg_user,c_attendance_reg_at,c_attendance_upd_user,c_attendance_upd_at,}/**
* 列挙体　カラム　v_supply4project
 */
export enum e_v_supply4project {c_supply_id,c_project_id,c_variation_id,c_supply_rownum,c_claim_id,c_supply_claimnum_tmp,c_supply_month,c_supply_item,c_supply_count,c_supply_date_start,c_supply_date_end,c_supplier_id,c_supply_price,c_supply_taxrate,c_supply_date_exp_payment,c_supply_flag_paid,c_supply_remarks,c_supply_flag_lock,c_supply_flag_insurance,c_supply_flag_alert,c_supply_reg_user,c_supply_reg_at,c_supply_upd_user,c_supply_upd_at,c_claim_number,c_item_id,c_variation_size,c_variation_name,c_variation_manag_name,c_item_type,c_item_name,c_item_insurance,c_supplier_name,c_item_manag_name,c_pro2doc_id,c_supply_flag_chkclaim,c_supply_subject,}