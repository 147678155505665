import { EntityStaff } from "../entity/EntityStaff";
import { EntityClient } from "../entity/EntityClient";
import { EntitySupplier } from "../entity/EntitySupplier";
import { EntityBankImple } from "../entity/EntityBankImple";
import { EntityHoliday } from "../entity/EntityHoliday";
import { EntityAggClientImple } from "../entity/EntityAggClientImple";
import { EntityAgg4client4rank } from "../entity/EntityAgg4client4rank";

export class DtoAggregatesGetRes {
  // バッジ 0用
  //-------------------
  public Badge_0_1_sales: number = 0;
  public Badge_0_2_profit: number = 0;
  public Badge_0_3_gale2salseRatio: number = 0;
  // public Badge_0_4_usageDateRatio: number = 0;
  public Badge_0_4_gale2profitRatio: number = 0;
  public Badge_0_5_profitRatio: number = 0;
  public Badge_0_targetDate: string = "";
  // 全体サマリ 100用
  //-------------------
  public summary_graph_100_chartData: any = null;
  // 全体サマリ 200用
  //-------------------
  public summary_list_200_listData: EntityAggClientImple[] = [];
  // 月別売上先[受注]売上・仕入額 300用
  //-------------------
  public client_graph_300_chartData_now: any = null;
  public client_graph_300_chartData_now4Suupy: any = null;
  public client_graph_300_chartData_now4Profit: any = null;
  public client_graph_300_chartData_onePrev: any = null;
  public client_graph_300_chartData_onePrev4Suupy: any = null;
  public client_graph_300_chartData_onePrev4Profit: any = null;
  public client_graph_300_chartData_twoPrev: any = null;
  public client_graph_300_max_x: number = 100000000;
  // 月別売上先（売上額） 400用
  //-------------------
  public client_graph_400_chartData: any = null;
  // 月別売上先（売上額） 500用
  //-------------------
  public client_list_500_listData: EntityAgg4client4rank[] = [];
  // 月別売上先（売上額） 600用
  //-------------------
  // public summary_list_600_listData: EntityAggClientImple[] = [];

  public async setClientName2Summary_list_200_listData(
    cnvClientId2Name: Map<string, string>
  ) {
    for (
      let index = 0;
      index < this.summary_list_200_listData.length;
      index++
    ) {
      if (
        cnvClientId2Name.has(
          this.summary_list_200_listData[index].c_client_id!.toString()
        )
      ) {
        this.summary_list_200_listData[index].c_client_name =
          cnvClientId2Name.get(
            this.summary_list_200_listData[index].c_client_id!.toString()
          )!;
      } else {
        this.summary_list_200_listData[index].c_client_name = "";
      }
    }
  }
}
