
import { Prop, Options, Vue } from "vue-property-decorator";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { EntityConditionImple } from "mediadept-common/src/common/entity/EntityConditionImple";
import Mo_modal_l from "@/components/molecules/Mo_modal_l.vue";
import { DtoConditionsPostReqImple } from "mediadept-common/src/common/dto/DtoConditionsPostReqImple";
import { Cl_condition } from "@/functions/Cl_condition";
import { Cl_common } from "@/functions/Cl_common";
import { VueGoodTable } from "vue-good-table-next";
import "vue-good-table-next/dist/vue-good-table-next.css";
import { DtoConditionsPutReq } from "mediadept-common/src/common/dto/abst/DtoConditionsPutReq";
import Mo_modal_alert from "@/components/molecules/Mo_modal_alert.vue";
import { EntitySupplier } from "mediadept-common/src/common/entity/EntitySupplier";
import { CONST_OP_SUPPLY_TYPE } from "mediadept-common/src/common/constant/Constants";

@Options({
  components: {
    vSelect,
    Mo_modal_l,
    VueGoodTable,
    Mo_modal_alert,
  },
})
export default class Mo_condition extends Vue {
  //----------------------
  //DOM連携用プロパティ
  //----------------------
  @Prop({ required: true })
  conditions: EntityConditionImple[];
  @Prop({ required: true })
  c_variation_id: number;
  @Prop({ required: true })
  items4Supplyers: EntitySupplier[];
  @Prop({ required: true })
  itemName: string;
  @Prop({ required: true })
  variationName: string;

  btnDisable = false;
  delIndex = 0;

  columns = [
    {
      label: "詳細",
      field: "詳細ボタン",
    },
    {
      label: "商品番号",
      field: "ANKEN_NO",
    },
    {
      label: "商品名",
      field: "ANKEN_NM",
    },
    {
      label: "商品種別",
      field: "KOUKOKU_NM",
    },
    {
      label: "所属",
      field: "SHOZOKU_NM",
    },
    {
      label: "エリア",
      field: "AREA_NM",
    },
  ];

  //----------------------
  //セレクトボックス用プロパティ
  //----------------------
  opSupplyType = CONST_OP_SUPPLY_TYPE;

  // optionsOp2 = [
  //   { label: "ビルボード" },
  //   { label: "ポスタージャック" },
  //   { label: "商業施設" },
  //   { label: "デジタルビルボード" },
  //   { label: "交通" },
  //   { label: "その他メディア" },
  //   { label: "その他" },
  // ];
  // optionsOp8 = [
  //   { label: "媒体料", code: 1 },
  //   { label: "製作・施工（取付撤去）", code: 2 },
  //   { label: "製作・取付", code: 3 },
  //   { label: "取付", code: 4 },
  //   { label: "撤去", code: 5 },
  //   { label: "撤去（前受）", code: 6 },
  //   { label: "プリント", code: 7 },
  //   { label: "工事（資産）", code: 8 },
  //   { label: "電気代", code: 9 },
  //   { label: "申請", code: 10 },
  //   { label: "その他", code: 11 },
  // ];

  //----------------------
  //制御用メソッド
  //----------------------

  async mounted() {
    //console.log(conditions);
  }

  //----------------------
  //基本操作用メソッド
  //----------------------
  async addCondition() {
    // let tmp = new EntityCondition();
    // tmp.c_variation_id = this.c_variation_id;
    // this.conditions.push(tmp);

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("仕入条件処理実行");

      //準備
      //-------
      let dto = new DtoConditionsPostReqImple();
      dto.c_variation_id = this.c_variation_id;
      dto.c_condition_name = this.itemName + " " + this.variationName + " ";
      dto.c_condition_flag_del = 0;
      //API実行
      //-------
      let res = await Cl_condition.post(dto);
      //レスポンスの処理
      //-------
      this.conditions.push(res);
      // リフレッシュしない
      //this.$emit("get");
      this.$emit("upd", false, false);
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");
    }
  }

  async delCondition(/*index: number*/) {
    this.closeModal4deleteConf();

    //ボタン無効＋ローダー表示
    //-----------------
    this.btnDisable = true;
    const panel = document.getElementById("panel-editers");
    const panel2 = document.getElementById("panel-editers4det");
    panel?.classList.add("enable-loader");
    panel2?.classList.add("enable-loader");

    try {
      //処理
      //-----------------
      Cl_common.logger("処理実行");

      //準備
      //-------
      let dto = new DtoConditionsPutReq();
      dto.c_condition_id = this.conditions[this.delIndex].c_condition_id;
      dto.c_condition_flag_del = 1;
      //API実行
      //-------
      let res = await Cl_condition.put(dto);
      console.log(res);
      //レスポンスの処理
      //-------
      this.conditions.splice(this.delIndex, 1);
      // 上のgetを呼ぶ
      //this.$emit("get");
      this.message4ModalSuccess = "正常に削除されました";

      //モーダルを表示
      const modal: any = this.$refs.Mo_modal_success_no_get;
      modal.showModal();
    } catch (error) {
      Cl_common.logger(error);
    } finally {
      //ボタン無効解除＋ローダー非表示
      //-----------------
      this.btnDisable = false;
      panel?.classList.remove("enable-loader");
      panel2?.classList.remove("enable-loader");
    }
  }

  async get() {
    this.$emit("get");
  }

  async upd(isDel: boolean, isShowModal = true) {
    this.$emit("upd", isDel, isShowModal);
  }

  /**
   * 商品選択時のイベント処理
   */
  async selectCondition(conditionId: number) {
    this.$emit("itemSelectCondi", conditionId);
  }

  /**
   * 金額セル入力時の処理
   * カンマを消す
   * @param obj
   */
  kanmaDel(obj: any) {
    // //カンマを消す
    obj.target.value = obj.target.value.replace(/,/g, "");
    if (obj.target.value == "0") obj.target.value = "";
  }
  /**
   * 金額セル入力時の処理
   * カンマを付ける
   * @param obj
   */
  kanmaIns(obj: any) {
    let val: string = obj.target.value;

    //うっかり入力しているかもしれないカンマを消す
    val = val.replace(/,/g, "");
    //整数に変換したのち文字列に戻す
    //この時点で数字とマイナス記号だけが残る
    let num = "" + parseInt(val);
    //正規表現で桁区切りするための良く見かける関数的な何か
    //変数 num の中身が、桁区切りされる
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
    //numに入っている値が数値じゃないときは空白とする
    if (isNaN(parseInt(num))) num = "";
    //桁区切りした結果（変数 num）でテキストボックスの中身を書き換える
    obj.target.value = num;
  }

  //----------------------
  //モーダル用プロパティ
  //----------------------
  message4ModalSuccess = "";
  message4ModalDelConf = "";

  //----------------------
  //モーダル用メソッド
  //----------------------
  async closeModal4deleteConf(): Promise<void> {
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.closeModal();
  }

  /**
   * データ削除用のモーダルを表示
   */
  async delConf(index: number) {
    this.delIndex = index;
    //モーダルのメッセージを設定
    this.message4ModalDelConf = "削除します。よろしいですか？";
    //モーダル表示
    const modal: any = this.$refs.Mo_modal_deleteConf;
    modal.showModal();
  }
}
